import { generateErrorLimitMsg, TOKEN_DECIMALS } from '@/common/constants/common.constants';
import { toEndOfDayUTC, toStartOfDayUTC } from '@/common/helpers';
import AppFormField from '@/components/atom/AppFormField';
import AppButton from '@/components/atom/Button/Button';
import AppDatePicker from '@/components/atom/Datepicker/Datepicker';
import AppImageUploading from '@/components/atom/ImageUploading/ImageUploading';
import AppTextArea from '@/components/atom/TextArea/TextArea';
import TextField from '@/components/atom/TextField/TextField';
import { EStatusProject } from '@/module/projectsList/constants';
import { IProject, IProjectForm, IStepOneForm, IStepOneFormData } from '@/module/projectsList/project.interface';
import { format } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { Control, Controller, FieldErrors, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { ImageListType } from 'react-images-uploading';
import { formatNumberWithCommas } from '@/lib/utils';

interface StepOneProps {
  onNextStep: (step: number, data: IStepOneForm) => void;
  onSaveDraft: (data: IStepOneForm) => void;
  allStepsData: IProjectForm;
  onFormChange: (data: IStepOneForm) => void;
  errorMsg?: string;
  register: UseFormRegister<IProject>;
  errors: FieldErrors<IProject>;
  watch: UseFormWatch<IProject>;
  control: Control<IProject>;
}

const StepOne: React.FC<StepOneProps> = ({
  onNextStep,
  onSaveDraft,
  allStepsData,
  onFormChange,
  register,
  errors,
  watch,
  control,
}) => {
  const [dateStart, setDateStart] = useState<Date | null>(null);
  const [dateEnd, setDateEnd] = useState<Date | null>(null);
  const [thumbnailUrl, setThumbnailUrl] = React.useState<ImageListType[]>([]);
  const [practitionerThumbnailUrl, setPractitionerThumbnailUrl] = React.useState<ImageListType[]>([]);

  const handleMapData = useCallback(
    (data: IStepOneFormData): IStepOneForm => {
      const {
        projectTitle,
        projectOverview,
        thumbnailImg,
        targetAmount,
        startDate,
        endDate,
        nameExecutor,
        profileExecutor,
        iconImgExecutor,
        xLink,
        insLink,
      } = data;

      return {
        title: projectTitle || '',
        summary: projectOverview || '',
        thumbnailUrl: thumbnailImg || (allStepsData.thumbnailUrl as string | File),
        targetApAmount: targetAmount,
        startDate:
          startDate !== undefined && !isNaN(startDate.valueOf()) ? toStartOfDayUTC(startDate).toISOString() : '',
        endDate: endDate !== undefined && !isNaN(endDate.valueOf()) ? toEndOfDayUTC(endDate).toISOString() : '',
        practitionerName: nameExecutor || '',
        practitionerProfile: profileExecutor || '',
        practitionerThumbnailUrl: iconImgExecutor || (allStepsData.practitionerThumbnailUrl as string | File),
        xUrl: xLink || '',
        instagramUrl: insLink || '',
      };
    },
    [allStepsData.practitionerThumbnailUrl, allStepsData.thumbnailUrl],
  );

  const onSubmit = useCallback(() => {
    let _data = {
      projectTitle: watch('projectTitle'),
      projectOverview: watch('projectOverview'),
      thumbnailImg: (thumbnailUrl as ImageListType)[0]?.file || (allStepsData.thumbnailUrl as string | File),
      targetAmount: watch('targetAmount'),
      startDate: watch('startDate'),
      endDate: watch('endDate'),
      nameExecutor: watch('nameExecutor'),
      profileExecutor: watch('profileExecutor'),
      iconImgExecutor:
        (practitionerThumbnailUrl as ImageListType)[0]?.file ||
        (allStepsData.practitionerThumbnailUrl as string | File),
      xLink: watch('xLink') || '',
      insLink: watch('insLink') || '',
    };
    const mappedData: IStepOneForm = handleMapData(_data);
    onNextStep(2, mappedData);
  }, [handleMapData, onNextStep]);

  const handleSaveDraft = useCallback(() => {
    const data = {
      projectTitle: watch('projectTitle'),
      projectOverview: watch('projectOverview'),
      thumbnailImg: (thumbnailUrl as ImageListType)[0]?.file || (allStepsData.thumbnailUrl as string | File),
      targetAmount: watch('targetAmount'),
      startDate: watch('startDate'),
      endDate: watch('endDate'),
      nameExecutor: watch('nameExecutor'),
      profileExecutor: watch('profileExecutor'),
      iconImgExecutor:
        (practitionerThumbnailUrl as ImageListType)[0]?.file ||
        (allStepsData.practitionerThumbnailUrl as string | File),
      xLink: watch('xLink') || '',
      insLink: watch('insLink') || '',
    };
    const mappedData: IStepOneForm = handleMapData(data);
    onSaveDraft(mappedData);
  }, [
    allStepsData.practitionerThumbnailUrl,
    allStepsData.thumbnailUrl,
    handleMapData,
    onSaveDraft,
    practitionerThumbnailUrl,
    thumbnailUrl,
    watch,
  ]);

  const onClickSaveDraft = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    handleSaveDraft();
  };

  const watchedValues = watch();

  useEffect(() => {
    const data = {
      projectTitle: watchedValues.projectTitle,
      projectOverview: watchedValues.projectOverview,
      thumbnailImg: (thumbnailUrl as ImageListType)[0]?.file || (allStepsData.thumbnailUrl as string | File),
      targetAmount: watchedValues.targetAmount,
      startDate: watchedValues.startDate,
      endDate: watchedValues.endDate,
      nameExecutor: watchedValues.nameExecutor,
      profileExecutor: watchedValues.profileExecutor,
      iconImgExecutor:
        (practitionerThumbnailUrl as ImageListType)[0]?.file ||
        (allStepsData.practitionerThumbnailUrl as string | File),
      xLink: watchedValues.xLink || '',
      insLink: watchedValues.insLink || '',
    };
    const mappedData: IStepOneForm = handleMapData(data);
    onFormChange(mappedData);
  }, [
    allStepsData.practitionerThumbnailUrl,
    allStepsData.thumbnailUrl,
    handleMapData,
    onFormChange,
    practitionerThumbnailUrl,
    thumbnailUrl,
    watchedValues,
  ]);

  return (
    <div className="px-20 py-16">
      <form className="flex flex-col items-start space-y-14">
        <div className="mb-2 flex w-full flex-col gap-6">
          <AppFormField
            textLabel="プロジェクトタイトル"
            required={true}
            hint={errors.projectTitle ? '' : generateErrorLimitMsg(5, 50)}
            errorText={errors.projectTitle?.message}
          >
            <TextField
              error={errors.projectTitle}
              placeholder="プロジェクトタイトルを入力する"
              {...register('projectTitle')}
            ></TextField>
          </AppFormField>

          <AppFormField
            textLabel="プロジェクト概要"
            required={true}
            hint={errors.projectOverview ? '' : generateErrorLimitMsg(30, 200)}
            errorText={errors.projectOverview?.message}
          >
            <Controller
              control={control}
              name="projectOverview"
              render={({ field }) => (
                <AppTextArea placeholder="プロジェクト概要を入力する" error={errors.projectOverview} {...field} />
              )}
            />
          </AppFormField>

          <AppFormField
            textLabel="サムネイル画像"
            required={true}
            hint={errors.thumbnailImg ? '' : '3:2(横1200px 縦800px 程度)'}
            errorText={errors.thumbnailImg?.message as string}
          >
            <Controller
              name="thumbnailImg"
              control={control}
              render={({ field: { onChange, value } }) => (
                <AppImageUploading
                  onChange={(image) => {
                    onChange(image);
                    setThumbnailUrl(image as never[]);
                  }}
                  value={thumbnailUrl}
                  defaultValue={allStepsData.thumbnailUrl}
                />
              )}
            />
          </AppFormField>

          <AppFormField textLabel="目標AP" required={true} errorText={errors.targetAmount?.message}>
            <Controller
              control={control}
              name="targetAmount"
              render={({ field }) => (
                <TextField
                  error={errors.targetAmount}
                  type="text"
                  placeholder="目標APを入力する"
                  {...field}
                  value={(() => {
                    const value = field.value ? formatNumberWithCommas(field.value) : '';
                    return value;
                  })()}
                  onChange={(e) => {
                    const value = e.target.value.replace(/,/g, '');
                    const newValue = Number(Number(value).toFixed(TOKEN_DECIMALS.AP));
                    field.onChange(newValue || '');
                  }}
                />
              )}
            />
          </AppFormField>

          <AppFormField textLabel="募集開始日" required={true} errorText={errors.startDate?.message}>
            <Controller
              control={control}
              name="startDate"
              render={({ field: { onChange, value, ref } }) => (
                <AppDatePicker
                  value={value}
                  dateFormat="yyyy/MM/dd"
                  error={errors.startDate}
                  minDate={new Date()}
                  maxDate={dateEnd}
                  placeholder={format(new Date(), 'yyyy/MM/dd')}
                  ref={ref}
                  onChange={(date) => {
                    onChange(date);
                    setDateStart(date);
                  }}
                />
              )}
            />
          </AppFormField>

          <AppFormField textLabel="募集終了日" required={true} errorText={errors.endDate?.message}>
            <Controller
              control={control}
              name="endDate"
              render={({ field: { onChange, value, ref } }) => (
                <AppDatePicker
                  dateFormat="yyyy/MM/dd"
                  minDate={dateStart}
                  error={errors.endDate}
                  value={value}
                  ref={ref}
                  placeholder={format(new Date(), 'yyyy/MM/dd')}
                  onChange={(date) => {
                    onChange(date);
                    setDateEnd(date);
                  }}
                />
              )}
            />
          </AppFormField>

          <AppFormField
            textLabel="プロジェクト実行者名"
            required={true}
            hint={errors.nameExecutor ? '' : generateErrorLimitMsg(3, 30)}
            errorText={errors.nameExecutor?.message}
          >
            <TextField
              error={errors.nameExecutor}
              placeholder="プロジェクト実行者名を入力する"
              {...register('nameExecutor')}
            ></TextField>
          </AppFormField>

          <AppFormField
            textLabel="プロジェクト実行者プロフィール"
            required={true}
            hint={errors.profileExecutor ? '' : generateErrorLimitMsg(50, 300)}
            errorText={errors.profileExecutor?.message}
          >
            <Controller
              control={control}
              name="profileExecutor"
              render={({ field }) => (
                <AppTextArea
                  custome="h-[125px]"
                  placeholder="プロジェクト実行者プロフィールを入力する"
                  error={errors.profileExecutor}
                  {...field}
                />
              )}
            />
          </AppFormField>

          <AppFormField
            textLabel="プロジェクト実行者アイコン画像"
            required={true}
            errorText={errors.iconImgExecutor?.message as string}
          >
            <Controller
              name="iconImgExecutor"
              control={control}
              render={({ field: { onChange, value } }) => (
                <AppImageUploading
                  width={'w-[220px]'}
                  height={'h-[220px]'}
                  onChange={(image) => {
                    onChange(image);
                    setPractitionerThumbnailUrl(image as never[]);
                  }}
                  value={practitionerThumbnailUrl}
                  defaultValue={allStepsData.practitionerThumbnailUrl}
                />
              )}
            />
          </AppFormField>

          <AppFormField textLabel="Xリンク" errorText={errors.xLink?.message}>
            <TextField error={errors.xLink} placeholder="Xリンクを入力する" {...register('xLink')}></TextField>
          </AppFormField>

          <AppFormField textLabel="Instagramリンク" errorText={errors.insLink?.message}>
            <TextField
              error={errors.insLink}
              placeholder="Instagramリンクを入力する"
              {...register('insLink')}
            ></TextField>
          </AppFormField>
        </div>

        <div className="flex gap-3">
          {(allStepsData.status === EStatusProject.DRAFT || allStepsData.status === undefined) && (
            <AppButton text="下書きを保存" variant="outline" onClick={(event) => onClickSaveDraft(event)} size="lg" />
          )}
          <AppButton text="次へ" size="lg" onClick={onSubmit} />
        </div>
      </form>
    </div>
  );
};

export default StepOne;
