import { IProjectDetailResponseData } from "@/module/projectsList/response/projectsList.interface";
import React, { useState } from "react";
import DetailCard from "./DetailCard";
import ProgressRate from "../../components/ProjectCard/components/ProgressRate";
import {
  displayData,
  formatFullDate,
  formatNumberToJapanese,
  formatToLocaleString,
} from "@/common/helpers";
import { format } from "date-fns";
import { FULL_DATE_FORMAT } from "@/common/constants/common.constants";
import { EStatusProject } from "@/module/projectsList/constants";
import CommercialTransactionsPopup from "./popup/CommercialTransactionsPopup";
import Tooltip from "@/components/atom/Tooltip/Tooltip";
import {formatNumberWithCommas} from '@/lib/utils';

interface DetailContentProps {
  detailData: IProjectDetailResponseData;
  projectId: string;
}

const DetailContent: React.FC<DetailContentProps> = ({
  detailData,
  projectId,
}) => {
  const {
    title,
    thumbnailUrl,
    targetApAmount,
    currentApAmount,
    amountProgress,
    countUser,
    remainingDays,
    xUrl,
    instagramUrl,
    todayApAmount,
    countUserToday,
    yesterdayApAmount,
    countUserYesterday,
    companyName,
    practitionerName,
    startDate,
    endDate,
    practitionerProfile,
    status,
    targetApAmountExchangeRate,
    currentApAmountExchangeRate,
  } = detailData;

  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);
  const arigatoMaxUrl = `${process.env.REACT_APP_ARIGATO_MAX_URL}/project-detail/${projectId}`;
  const [commercialTransactionsPopup, setCommercialTransactionsPopup] =
    useState(false);
  const [copyText, setCopyText] = useState("");

  const handleCopy = () => {
    navigator.clipboard.writeText(arigatoMaxUrl).then(() => {
      setCopyText("URLがコピーされました！");
      setTimeout(() => {
        setCopyText("");
      }, 1000);
    });
  };
  return (
    <div className="sm:w-[91.5%] md:w-[91.5%] lg:w-[800px]">
      <h1 className="font-bold text-3xl text-neutral-850">{title}</h1>
      <div className="w-full object-cover my-9 rounded-3xl">
        <img
          className="object-cover w-full lg:h-[28rem] md:h-[21rem] sm:h-[11rem] rounded-3xl"
          src={thumbnailUrl ? thumbnailUrl : "/assets/images/no-image.png"}
          alt=""
        />
      </div>
      <div className="my-9">
        <DetailCard title="現在の支援金額">
          <div className="mt-3">
            <div className="flex justify-between items-center gap-2">
              <img
                className="bg-primary-500 p-2 rounded-full"
                src="/assets/images/arigatomax-white-logo.svg"
                alt="img"
                width={40}
                height={40}
              />
              <div className="text-primary-500 font-bold text-4xl">
                {formatNumberWithCommas(currentApAmount)}
                <span className="text-gray-500 ml-3">AP</span>
              </div>
            </div>
            <div className="flex justify-end text-secondary-500 font-bold text-xl text-right">
              ({formatNumberToJapanese(currentApAmountExchangeRate)}
              円)
            </div>
            <ProgressRate
              customeProgress="h-4"
              customeText="text-2xl"
              amountProgress={parseInt(amountProgress?.replace("%", ""), 10) || 0}
            />
          </div>
        </DetailCard>
      </div>

      <div className="my-9 flex justify-between items-center gap-6">
        <div className="flex-1">
          <DetailCard title="目標金額" customeClass={"px-4 py-3"}>
            <div className="mt-6">
              <div className="flex justify-between items-center gap-[5px]">
                <img
                  className="bg-primary-500 p-3 rounded-full"
                  src="/assets/images/arigatomax-white-logo.svg"
                  alt="img"
                  width={49}
                  height={49}
                />
                <div className="flex justify-end flex-col text-secondary-500 font-bold text-[28px] gap-1 leading-8">
                  <div className="text-neutral-850 font-bold text-3xl text-end">
                    {formatNumberWithCommas(targetApAmount)}
                    <span className="text-gray-500 ml-3 text-2xl">AP</span>
                  </div>
                  <div className="text-secondary-500 text-xl leading-6 text-right">
                    ({formatNumberToJapanese(targetApAmountExchangeRate)}
                    円)
                  </div>
                </div>
              </div>
            </div>
          </DetailCard>
        </div>

        <div className="flex-1">
          <DetailCard title="支援者" customeClass={"px-4 py-3"}>
            <div className="flex justify-between items-start gap-2 mt-6">
              <img
                className="p-2"
                src="/assets/images/user-card-icon.svg"
                alt="img"
                width={49}
                height={49}
              />
              <div className="flex items-center mb-9">
                <div className="text-neutral-850 font-bold text-[28px] leading-7 align-middle">
                  {formatToLocaleString(countUser)}
                </div>
                <div className="text-gray-500 ml-3 text-2xl leading-7 font-bold">
                  人
                </div>
              </div>
            </div>
          </DetailCard>
        </div>
      </div>

      <div className="my-9 flex justify-between items-center gap-6">
        <div className="flex-1">
          <DetailCard title="募集終了まで" customeClass={"px-4 py-3"}>
            <div className="mt-6">
              <div className="flex justify-between gap-[5px] items-start">
                <img
                  className="p-2"
                  src="/assets/images/calendar-card-icon.svg"
                  alt="img"
                  width={49}
                  height={49}
                />
                <div className="flex items-center mb-9">
                  <div className="text-neutral-850 font-bold text-[28px] leading-7 align-middle">
                    {formatToLocaleString(remainingDays)}
                  </div>
                  <div className="text-gray-500 ml-3 text-2xl leading-7 font-bold">
                    日
                  </div>
                </div>
              </div>
            </div>
          </DetailCard>
        </div>

        <div className="flex-1">
          <DetailCard title="SNS" customeClass={"px-4 py-3"}>
            <div className="flex justify-end items-center gap-2 mt-5 mb-5">
              <a href={xUrl}>
                <img
                  src="/assets/images/icon_X.svg"
                  alt="img"
                  width={47}
                  height={47}
                />
              </a>
              <a href={instagramUrl}>
                <img
                  src="/assets/images/icon_instagram.svg"
                  alt="img"
                  width={47}
                  height={47}
                />
              </a>
            </div>
          </DetailCard>
        </div>
      </div>

      <div className="my-9 flex justify-between items-center gap-6">
        <div className="flex-1">
          <DetailCard
            title={`本日の状況 ${format(today, FULL_DATE_FORMAT)}`}
            customeClass={"p-4"}
          >
            <div className="mt-2 flex gap-2">
              <DetailCard title="AP" variant="sub" customeClass="p-2 flex-1">
                <div className="flex justify-between items-end gap-2 mt-1">
                  <img
                    className="bg-primary-500 p-2 rounded-full"
                    src="/assets/images/arigatomax-white-logo.svg"
                    alt="img"
                    width={32}
                    height={32}
                  />
                  <div className="flex items-end">
                    <div className="text-neutral-850 font-bold text-2xl leading-7">
                      {formatToLocaleString(todayApAmount)}
                    </div>
                    <div className="text-gray-500 text-base font-bold">AP</div>
                  </div>
                </div>
              </DetailCard>
              <DetailCard
                title="支援者"
                variant="sub"
                customeClass="p-2 flex-1"
              >
                <div className="flex justify-between items-end gap-2 mt-1">
                  <img
                    src="/assets/images/user-card-icon.svg"
                    alt="img"
                    width={32}
                    height={32}
                  />
                  <div className="flex items-end">
                    <div className="text-neutral-850 font-bold text-2xl leading-7">
                      {formatToLocaleString(countUserToday)}
                    </div>
                    <div className="text-gray-500 text-base font-bold">人</div>
                  </div>
                </div>
              </DetailCard>
            </div>
          </DetailCard>
        </div>

        <div className="flex-1">
          <DetailCard
            title={`昨日の状況 ${format(yesterday, FULL_DATE_FORMAT)}`}
            customeClass={"p-4"}
          >
            <div className="mt-2 flex gap-2">
              <DetailCard title="AP" variant="sub" customeClass="p-2 flex-1">
                <div className="flex justify-between items-end gap-2 mt-1">
                  <img
                    className="bg-primary-500 p-2 rounded-full"
                    src="/assets/images/arigatomax-white-logo.svg"
                    alt="img"
                    width={32}
                    height={32}
                  />
                  <div className="flex items-end">
                    <div className="text-neutral-850 font-bold text-2xl leading-7">
                      {formatToLocaleString(yesterdayApAmount)}
                    </div>
                    <div className="text-gray-500 text-base font-bold">AP</div>
                  </div>
                </div>
              </DetailCard>
              <DetailCard
                title="支援者"
                variant="sub"
                customeClass="p-2 flex-1"
              >
                <div className="flex justify-between items-end gap-2 mt-1">
                  <img
                    src="/assets/images/user-card-icon.svg"
                    alt="img"
                    width={32}
                    height={32}
                  />
                  <div className="flex items-end">
                    <div className="text-neutral-850 font-bold text-2xl leading-7">
                      {formatToLocaleString(countUserYesterday)}
                    </div>
                    <div className="text-gray-500 text-base font-bold">人</div>
                  </div>
                </div>
              </DetailCard>
            </div>
          </DetailCard>
        </div>
      </div>

      <div className="my-9 flex justify-between items-center gap-9">
        <DetailCard title="会社名" customeClass={"px-4 py-3 flex-1"}>
          <div className="mt-6">
            <div className="text-neutral-850 font-bold font-base text-right">
              {displayData(companyName)}
            </div>
          </div>
        </DetailCard>
        <DetailCard title="実行者名" customeClass={"px-4 py-3  flex-1"}>
          <div className="mt-6">
            <div className="text-neutral-850 font-bold font-base text-right">
              {displayData(practitionerName)}
            </div>
          </div>
        </DetailCard>
        <DetailCard title="開始日〜終了日" customeClass={"px-4 py-3  flex-1"}>
          <div className="mt-6">
            <div className="text-neutral-850 font-bold font-base text-right">
              {displayData(formatFullDate(startDate))}〜
              {displayData(formatFullDate(endDate))}
            </div>
          </div>
        </DetailCard>
      </div>

      <div className="my-9">
        <DetailCard title="プロデューサー" customeClass={"px-4 py-3"}>
          <div className="text-neutral-850 text-2xl font-bold my-6">
            {displayData(companyName)} {displayData(practitionerName)}
          </div>
          <div className="text-secondary-400 font-medium text-sm">
            {displayData(practitionerProfile)}
          </div>
          <div
            className="text-link text-sm font-medium text-center mt-6 cursor-pointer"
            onClick={() => setCommercialTransactionsPopup(true)}
          >
            特定商取引に基づく表記
          </div>
        </DetailCard>
      </div>
      <CommercialTransactionsPopup
        detailData={detailData}
        openPopup={commercialTransactionsPopup}
        handleConfirm={() => setCommercialTransactionsPopup(false)}
      />

      {(status === EStatusProject.PUBLISHED ||
        status === EStatusProject.EDITING) && (
        <div className="my-9">
          <DetailCard title="公開URL" customeClass={"px-4 py-3"}>
            <div className="text-secondary-500 font-medium text-base mt-2 mb-4">
              プロジェクトを公開したら、SNSで告知して一人でも多くの人に届けましょう
            </div>
            <div className="text-primary-400 font-bold text-sm mb-1">URL</div>
            <div className="border rounded-md flex justify-end items-center gap-2 px-[14px] py-[10px] mb-7">
              <p className="text-secondary-500 font-medium text-[11px]">
                {arigatoMaxUrl}
              </p>

              <Tooltip open={copyText !== ""} sideOffset={5} content={copyText}>
                <img
                  onClick={handleCopy}
                  className="cursor-pointer"
                  src="/assets/images/copy-icon.svg"
                  alt=""
                  width={16}
                  height={16}
                />
              </Tooltip>
            </div>
          </DetailCard>
        </div>
      )}
    </div>
  );
};

export default DetailContent;
