import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}


export function formatNumberWithCommas<T extends number | string | null | undefined>(
    value: T,
): T extends null | undefined ? T : string {
    if (value === null || value === undefined) {
        return value as unknown as T extends null | undefined ? T : string; 
    }

    const number = (typeof value === 'string' ? parseFloat(value) : value) as number;

    if (isNaN(number)) {
        return value as T as unknown as T extends null | undefined ? T : string;
    }
    return number.toLocaleString()  as T extends null | undefined ? T : string;
}