import React, { useCallback } from "react";
import AppButton from "@/components/atom/Button/Button";
import ImageDisplay from "@/components/atom/ImageDisplay";
import Heading1 from "@/components/common/Heading1";
import { EStatusProject } from "@/module/projectsList/constants";
import {formatNumberWithCommas} from '@/lib/utils'

export interface IStep3DataItem {
  id: string;
  img: string | File;
  title: string;
  description: string;
  points: string;
}

interface IListScreenProps {
  onCreateButtonClick: () => void;
  onNextStep: () => void;
  onItemClick: (id: string) => void;
  onSaveDraft: () => void;
  data: IStep3DataItem[];
  status?: EStatusProject;
}

const ListScreen: React.FC<IListScreenProps> = (props) => {
  const {
    onCreateButtonClick,
    onNextStep,
    onItemClick,
    onSaveDraft,
    data,
    status,
  } = props;

  const handleSwitchScreen = useCallback(() => {
    onCreateButtonClick();
  }, [onCreateButtonClick]);

  const handleNextStep = useCallback(() => {
    onNextStep();
  }, [onNextStep]);

  const handleItemClick = useCallback(
    (id: string) => {
      onItemClick(id);
    },
    [onItemClick]
  );

  const handleSaveDraft = useCallback(() => {
    onSaveDraft();
  }, [onSaveDraft]);

  return (
    <div className="flex flex-col items-center lg:block">
      <div className="w-full flex justify-center lg:justify-end">
        <AppButton text="リターン追加" size="lg" onClick={handleSwitchScreen} />
      </div>
      {data.length > 0 ? (
        <div className="w-full grid 2xl:grid-cols-4 lg:grid-cols-3 grid-cols-2 gap-y-10 gap-x-10  my-[4rem] justify-items-stretch">
          {data.map((item) => (
            <div
              className={` hover:border-primary-500 mb-6 hover:shadow-project-card-hover cursor-pointer shadow-project-card  rounded-2xl border border-outline-200 p-2`}
              key={item.id}
              onClick={() => handleItemClick(item.id)}
            >
              <ImageDisplay
                className="rounded-xl mb-2.5 aspect-square w-full object-cover"
                url={item.img}
                alt="img"
              />
              <h1 className="text-neutral-850 text-sm font-bold line-clamp-1">
                {item.title}
              </h1>
              <p className="text-secondary-400 text-[13px] font-medium h-[35px] line-clamp-2">
                {item.description}
              </p>
              <div className="w-full flex justify-between items-center px-2 rounded-xl bg-[#E5F6FF] h-[20px] my-2">
                <img src={"/assets/images/icon_token.png"} alt="img" />
                <p className="text-neutral-850 text-[13px] font-bold">
                  {formatNumberWithCommas(item.points)}AP
                </p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex justify-center">
          <Heading1 text="リターン無し" />
        </div>
      )}
      <div className="mt-[10rem] flex gap-3">
        {(status === EStatusProject.DRAFT || status === undefined) && (
          <AppButton
            text="下書きを保存"
            size="lg"
            variant="outline"
            onClick={handleSaveDraft}
          />
        )}
        <AppButton text="次へ" size="lg" onClick={handleNextStep} />
      </div>
    </div>
  );
};

export default ListScreen;
